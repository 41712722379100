/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
var seamlessScroll = require('seamless-scroll-polyfill');

exports.onClientEntry = () => {
  seamlessScroll.polyfill();
};